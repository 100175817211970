import { ref, onMounted, reactive, defineComponent } from 'vue'
import DataTable from '@/views/components/table/HjpDataTable.vue'
import BankCredentialForm from '@/views/pages/master-setting/bank-credential/Form.vue'
import {
  get, map
} from 'lodash'

export default defineComponent({
  components: {
    DataTable,
    BankCredentialForm
  },
  setup() {
    const tabelParams = reactive({
      endpoint: '/management/v1/MsCredentialBank',
      endpointSuffix: '/search',
      headerTitle: 'Master ID Credential Bank',
      buttonTitle: 'Tambah ID',
      editTitle: 'Edit',
      confirmDialogDelete: {
        title: 'Hapus Credential Bank?',
        subTitle: 'Menghapus data ini akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Credential bank?',
        btnSubmitTitle: 'Hapus Credential Bank',
      },
      columnsTable: [
        {
          field: 'IdCorporate',
          headerName: 'Corporate ID',
          searchable: true,
          fieldSearch: 'query',
          isRender: false
        },
        {
          field: 'MsChannelBanks.OaAccount',
          headerName: 'Channel ID',
          fieldSearch: 'channelId',
          isRender: true,
        },
        {
          field: 'MsBank.Name',
          headerName: 'Bank',
          searchable: false,
          fieldSearch: 'bankName',
          isRender: true
        },
      ]
    })
    const slotBank = 'MsBank.Name'
    const slotChannelBank = 'MsChannelBanks.OaAccount'
    const getBankChannelId = (data: any) => {
      console.log('data', data)
    }
    return {
      tabelParams,
      slotBank,
      slotChannelBank,
      getBankChannelId,
      get,
      map
    }
  }
})
