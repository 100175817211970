import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BankCredentialForm = _resolveComponent("BankCredentialForm")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      columns: _ctx.tabelParams.columnsTable,
      endpoint: _ctx.tabelParams.endpoint,
      headerTitle: _ctx.tabelParams.headerTitle,
      buttonTitle: _ctx.tabelParams.buttonTitle,
      editTitle: _ctx.tabelParams.editTitle,
      showColumnActivate: true,
      confirmDialogDelete: _ctx.tabelParams.confirmDialogDelete
    }, {
      [_ctx.slotChannelBank]: _withCtx(({ slotProps }) => [
        _createTextVNode(_toDisplayString(slotProps.data.MsChannelBanks.map((val) => val.OaAccount).join(', ')), 1)
      ]),
      [_ctx.slotBank]: _withCtx(({ slotProps }) => [
        _createTextVNode(_toDisplayString(_ctx.get(slotProps.data, _ctx.slotBank)), 1)
      ]),
      form: _withCtx(() => [
        _createVNode(_component_BankCredentialForm)
      ]),
      _: 2
    }, 1032, ["columns", "endpoint", "headerTitle", "buttonTitle", "editTitle", "confirmDialogDelete"])
  ]))
}